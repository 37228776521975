const serialize = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + ':' + encodeURIComponent(obj[p]));
    }
  return str.join(';');
};

export const getVeemURL = ({ amount, currency, shipmentNumber }) => {
  const params = serialize({
    referenceId: `fe79b5c0-0e36-4890-91e0-52919792556f`,
    purposeOfPayment: 'Services',
    purposeOfPaymentDescription: `Freightos shipment #${shipmentNumber}`,
    payeeCurrencyCode: currency,
    payeeAmount: amount
  });
  return `https://apps.veem.com/CustomerApp/Dashboard/SendPayment?preset=${params}`;
};

export function DefaultCardConstructor(last4, brand, expiryDate, registered = true) {
  this.expiry = expiryDate;
  this.last4 = last4;
  this.brand = brand;
  this.registered = registered; // currently we don't have the option
  // of a card that is in the system but not registered to autopay
}
