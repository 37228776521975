import * as AT from 'financialSharedComponents/constants/actionTypes';

export const fetchBankDetails = (payload) => ({
  type: AT.FETCH_BANK_DETAILS,
  payload
});

export const fetchBankDetailsSuccess = (payload) => ({
  type: AT.FETCH_BANK_DETAILS_SUCCESS,
  payload
});

export const fetchBankDetailsFail = (payload) => ({
  type: AT.FETCH_BANK_DETAILS_FAIL,
  payload
});
