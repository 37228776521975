import { connect } from 'react-redux';
import CreditCardsMgmt from './CreditCardsMgmt';
import * as actions from './actions';
import * as paymentsActions from 'financialSharedComponents/components/Payment/actions';
import * as selectors from 'financialSharedComponents/selectors';
import { flagStateOrDefaultValueSelector } from 'feature-flagging';
import * as ldFlags from 'financialSharedComponents/ldFlags';

const mapStateToProps = (state) => ({
  isChangingCard: selectors.isChangingCardSelector(state),
  isCardsListInitialized: selectors.cardsListInitializedSelector(state),
  isBTIntegrationEnabled: flagStateOrDefaultValueSelector(
    state,
    ldFlags.BRAIN_TREE_INTEGRATION_FLAG,
    true
  )
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitToken: ({ nonce, pspName }) => {
      dispatch(
        actions.saveCardToken({
          nonce,
          pspName,
          triggerPayment: true
        })
      );
    },
    fetchUserCards: () => dispatch(actions.fetchUserCards()),
    fetchPaymentMethods: () => dispatch(paymentsActions.fetchPaymentMethods()),
    setIsChangingCard: (payload) => dispatch(actions.setIsChangingCard(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardsMgmt);
