import * as AT from 'financialSharedComponents/constants/actionTypes';

export const fetchSupportedPaymentTypes = (payload) => ({
  type: AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES,
  payload
});

export const initPaymentData = (payload) => ({
  type: AT.INIT_PAYMENT_DATA,
  payload
});

export const fetchSupportedPaymentTypesSuccess = (payload) => ({
  type: AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_SUCCESS,
  payload
});

export const fetchPSPKey = (payload) => ({
  type: AT.FETCH_PSP_KEY,
  payload
});

export const fetchPaymentMethods = (payload) => ({
  type: AT.FETCH_PAYMENT_METHODS,
  payload
});
export const fetchPaymentMethodsSuccess = (payload) => ({
  type: AT.FETCH_PAYMENT_METHODS_SUCCESS,
  payload
});
export const fetchPaymentMethodsFail = (payload) => ({
  type: AT.FETCH_PAYMENT_METHODS_FAIL,
  payload
});
export const fetchPSPKeySuccess = (payload) => ({
  type: AT.FETCH_PSP_KEY_SUCCESS,
  payload
});

export const fetchSupportedPaymentTypesFail = (payload) => ({
  type: AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_FAIL,
  payload
});

export const doPayment = (payload) => ({
  type: AT.DO_PAYMENT,
  payload
});

export const doPaymentSuccess = (payload) => ({
  type: AT.DO_PAYMENT_SUCCESS,
  payload
});

export const doPaymentFail = (payload) => ({
  type: AT.DO_PAYMENT_FAIL,
  payload
});

