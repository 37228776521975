import { createSelector } from 'reselect';
import { PACKAGE_NAME } from 'constants/app.constants';

import siteConfig from 'propera/siteConfig';
import get from 'lodash/get';
import { SUPPORTED_PAYMENT_METHODS_TYPES } from 'financialSharedComponents/constants/app.constants';

const finState = (state) => get(state, [PACKAGE_NAME]);
const rootState = state => state;

const currentTransactionSelector = createSelector(finState, (state) =>
  get(state, 'currentTransaction')
);
export const bankDetailsSelector = createSelector(finState, (state) =>
  get(state, 'freightosBankDetails.details')
);

export const bankDetailsFetchingSelector = createSelector(finState, (state) =>
  get(state, 'freightosBankDetails.fetching')
);

export const currentTransactionCurrencySelector = createSelector(
  currentTransactionSelector,
  (state) => get(state, 'paymentAmount.currency')
);

export const currentTransactionAmountSelector = createSelector(
  currentTransactionSelector,
  (state) => get(state, 'paymentAmount.amount')
);

export const currentTransaction = createSelector(finState, (state) =>
  get(state, 'currentTransaction.paymentAmount.currency')
);

export const currentTransactionShipmentNumberSelector = createSelector(
  currentTransactionSelector,
  (state) => get(state, 'metadata.shipmentNumber')
);

export const selectedPaymentMethodPSPNameSelector = createSelector(finState, (state) =>
  get(state, 'selectedPaymentMethod.pspName')
);

export const paymentMethodTypesSelector = createSelector(finState, rootState, (state, parentState) => {
  const isApplyForCreditButtonDisabled = siteConfig.isApplyForCreditButtonDisabled(parentState);
  const paymentMethods = get(state, 'paymentMethodTypes', {});
  const {[SUPPORTED_PAYMENT_METHODS_TYPES.CREDIT_LINE]: placeholder, ...noCreditLine} = paymentMethods;
  return !isApplyForCreditButtonDisabled? paymentMethods: noCreditLine 
}
);

export const selectedPaymentMethodSelector = createSelector(finState, (state) =>
  get(state, 'selectedPaymentMethod.method')
);

export const cardErrorMessageSelector = createSelector(finState, (state) =>
  get(state, 'cardErrorMessage')
);

export const availableCreditSelector = createSelector(finState, (state) =>
  get(state, 'availableCredit')
);

export const isChangingCardSelector = createSelector(finState, (state) =>
  get(state, 'userCards.isChangingCard')
);
export const cardsListInitializedSelector = createSelector(finState, (state) =>
  get(state, 'userCardsInitialized')
);

export const moneyTransferOptionsSelector = createSelector(finState, (state) =>
  get(state, ['paymentMethodTypes', 'BANK_TRANSFER'])
);

export const stripeApiKeySelector = createSelector(finState, (state) =>
  get(state, 'PSPKeys.stripe')
);
export const brainTreeApiKeySelector = createSelector(finState, (state) =>
  get(state, 'PSPKeys.braintree')
);

export const paymentMethodTypesBusySelector = createSelector(finState, (state) =>
  get(state, 'busy')
);

export const paymentMethodTypesErrorSelector = createSelector(finState, (state) =>
  get(state, 'error')
);

export const paymentInProgressSelector = createSelector(finState, (state) =>
  get(state, 'currentTransaction.inProgress')
);

export const defaultCardSelector = createSelector(finState, (state) =>
  get(state, 'cards.default')
);
