import { connect } from 'react-redux';
import 'styles/main.scss';
import get from 'lodash/get';
import { flagStateOrDefaultValueSelector } from 'feature-flagging';
import * as selectors from 'financialSharedComponents/selectors';
import * as ldFlags from 'financialSharedComponents/ldFlags';
import { DefaultCardConstructor } from './utils';
import PaymentView from './Payment';
import * as actions from './actions';

const mapStateToProps = (state) => ({
  isBTIntegrationEnabled: flagStateOrDefaultValueSelector(
    state,
    ldFlags.BRAIN_TREE_INTEGRATION_FLAG,
    true
  ),
  paymentMethodsBusy: selectors.paymentMethodTypesBusySelector(state),
  defaultCardFromPaymentService: selectors.defaultCardSelector(state),
  selectedPaymentMethod: selectors.selectedPaymentMethodSelector(state),
  cardErrorMessage: selectors.cardErrorMessageSelector(state),
  selectedPaymentMethodPspName: selectors.selectedPaymentMethodPSPNameSelector(state),
  paymentMethodsError: selectors.paymentMethodTypesErrorSelector(state)
});
const mapDispatchToProps = (dispatch, ownProps) => {
  const { paymentAmount } = ownProps;
  return {
    fetchSupportedPaymentTypes: () => dispatch(actions.fetchSupportedPaymentTypes(paymentAmount)),
    initPaymentData: (payload) => dispatch(actions.initPaymentData(payload))
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  let { defaultCard } = ownProps;
  let { isBTIntegrationEnabled, defaultCardFromPaymentService } = stateProps;
  if (isBTIntegrationEnabled) {
    defaultCard = defaultCardFromPaymentService;
  } else {
    defaultCard = new DefaultCardConstructor(
      get(defaultCard, 'last4'),
      get(defaultCard, 'brand'),
      get(defaultCard, 'expiry'),
      get(defaultCard, 'registered')
    );
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    defaultCard
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaymentView);
