import React from 'react';

const VeemWordLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80px" viewBox="0 0 256.23 54.82">
    <title>Veem_primary_logo_notag_hex</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#ff8200"
          d="M31.76,33.46C30.11,30.64,15.24,4,15.24,4A8.29,8.29,0,0,0,3.59,1.69h0a8.5,8.5,0,0,0-2.06,11.8l20.63,36.9A8.49,8.49,0,0,0,29.37,54a8.31,8.31,0,0,0,7.2-3.58l8.69-15.62a7.89,7.89,0,0,1-10.88,1.66A13,13,0,0,1,31.76,33.46Z"
        />
        <path
          fill="#007fe0"
          d="M105,33.62H76.48c4.71,7.17,13.6,8.24,20.45,4.5a7.22,7.22,0,0,1,10,2.57,7.42,7.42,0,0,1-2.57,10.06A30.31,30.31,0,0,1,89,54.82c-12.32,0-22-7-26.24-15.85a27.79,27.79,0,0,1-.32-22.27A27.3,27.3,0,0,1,114.7,24C115.35,30.41,111.06,33.62,105,33.62ZM98.43,21.41c-1-4.06-5.46-7.92-10.92-7.92s-10,3.86-10.92,7.92Z"
        />
        <path
          fill="#007fe0"
          d="M163.16,33.62H134.68c4.71,7.17,13.6,8.24,20.45,4.5a7.22,7.22,0,0,1,10,2.57,7.42,7.42,0,0,1-2.57,10.06,30.31,30.31,0,0,1-15.31,4.07c-12.32,0-22-7-26.24-15.85a27.79,27.79,0,0,1-.32-22.27A27.3,27.3,0,0,1,172.9,24C173.55,30.41,169.26,33.62,163.16,33.62Zm-6.53-12.21c-1-4.06-5.46-7.92-10.92-7.92s-10,3.86-10.92,7.92Z"
        />
        <path
          fill="#007fe0"
          d="M240.4,2.53l-.62-.28c-.61-.24-1.22-.47-1.85-.67-.07,0-1.55-.44-2.24-.59-.11,0-1.63-.29-2.29-.36C232.9.58,231.32.5,231,.5h-.11a23.83,23.83,0,0,0-14.88,5.36A23.83,23.83,0,0,0,201.17.5h-.1a26.52,26.52,0,0,0-4.65.49,24.35,24.35,0,0,0-4.09,1.26l-.63.28a23.4,23.4,0,0,0-13.93,21.26V45.5a8.14,8.14,0,0,0,.59,3,6.39,6.39,0,0,0,.26.6,7,7,0,0,0,.36.66,8.66,8.66,0,0,0,.68,1,2.09,2.09,0,0,1,.14.17,8.36,8.36,0,0,0,3.74,2.55,4,4,0,0,0,.45.15q.39.1.78.18a9.18,9.18,0,0,0,1.47.14,8.56,8.56,0,0,0,8.47-8.47V23.79a6.42,6.42,0,0,1,6.41-6.45,6.48,6.48,0,0,1,6.41,6.45V45.5A8.56,8.56,0,0,0,216,54h.1a8.57,8.57,0,0,0,8.48-8.47V23.79a6.41,6.41,0,1,1,12.81,0V45.5A8.56,8.56,0,0,0,245.86,54a9.78,9.78,0,0,0,1.47-.14q.4-.07.78-.18a3.53,3.53,0,0,0,.46-.15A8.39,8.39,0,0,0,252.3,51l.14-.17a7.54,7.54,0,0,0,.68-1,7,7,0,0,0,.37-.66c.09-.2.18-.39.26-.6a8.36,8.36,0,0,0,.38-1.23c0-.06,0-.12,0-.18a8.56,8.56,0,0,0,.16-1.62V23.79A23.38,23.38,0,0,0,240.4,2.53Z"
        />
        <path
          fill="#007fe0"
          d="M54.91,1.67h0A8.5,8.5,0,0,0,43.15,4L30.38,26.83c1.27,2.28,2.29,4.05,2.67,4.67a14.09,14.09,0,0,0,2.35,2.9c4.85,3.4,9.7-.19,11.24-2.09L57.21,13.43A8.5,8.5,0,0,0,54.91,1.67Z"
        />
        <path
          fill="#007fe0"
          d="M248.21,4.36a4,4,0,0,1,.31-1.59,4.09,4.09,0,0,1,.87-1.27,4.27,4.27,0,0,1,1.28-.85,4.15,4.15,0,0,1,3.1,0,4.27,4.27,0,0,1,1.28.85,3.91,3.91,0,0,1,.86,1.27,3.83,3.83,0,0,1,.32,1.59,4,4,0,0,1-1.18,2.86,4.27,4.27,0,0,1-1.28.85,4.15,4.15,0,0,1-3.1,0,4.27,4.27,0,0,1-1.28-.85A4,4,0,0,1,248.52,6,4,4,0,0,1,248.21,4.36Zm.71,0a3.4,3.4,0,0,0,.26,1.36,3.35,3.35,0,0,0,1.74,1.81,3.35,3.35,0,0,0,1.3.26,3.27,3.27,0,0,0,1.29-.26,3.2,3.2,0,0,0,1-.73,3.22,3.22,0,0,0,.71-1.08,3.66,3.66,0,0,0,0-2.71,3.17,3.17,0,0,0-.71-1.09,3.2,3.2,0,0,0-1-.73,3.27,3.27,0,0,0-1.29-.26,3.35,3.35,0,0,0-1.3.26,3.2,3.2,0,0,0-1,.73,3.15,3.15,0,0,0-.7,1.09A3.38,3.38,0,0,0,248.92,4.36ZM250.69,2h1.77c1.09,0,1.64.44,1.64,1.34a1.14,1.14,0,0,1-.36.93,1.61,1.61,0,0,1-.88.35L254.2,6.7h-.76l-1.27-2h-.77v2h-.71Zm.71,2.09h.73a3.62,3.62,0,0,0,.47,0A1.24,1.24,0,0,0,253,4a.77.77,0,0,0,.28-.23.83.83,0,0,0,.1-.45.74.74,0,0,0-.09-.38.65.65,0,0,0-.25-.22,1.13,1.13,0,0,0-.34-.1l-.36,0h-.94Z"
        />
      </g>
    </g>
  </svg>
);

export { VeemWordLogo };
