import * as AT from 'financialSharedComponents/constants/actionTypes';
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  SUPPORTED_MONEY_TRANSFER_PSP_NAMES,
  SUPPORTED_PAYMENT_METHODS_TYPES
} from 'financialSharedComponents/constants/app.constants';
import { DefaultCardConstructor } from './components/Payment/utils';

const initialState = {
  paymentMethodTypes: {},
  cards: {
    default: null,
    list: [],
    busy: false,
    error: null
  },
  availableCredit: {},
  userCards: {
    list: [],
    isChangingCard: false,
    default: null
  },
  PSPKeys: {
    stripe: '',
    braintree: ''
  },
  freightosBankDetails: {
    details: {},
    fetching: true
  },
  selectedPaymentMethod: {
    method: null,
    pspName: null,
    metadata: {}
  },
  currentTransaction: {
    initialPaymentMethodType: null,
    paymentAmount: null,
    inProgress: false,
    metadata: {}
  },
  busy: true,
  userCardsInitialized: false,
  error: false,
  cardErrorMessage: null
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_FAIL: {
      return flow([set(['paymentMethodTypes'], []), set(['busy'], false), set(['error'], true)])(
        state
      );
    }
    case AT.FETCH_PAYMENT_METHODS: {
      return flow([
        set(['cards', 'list'], []),
        set(['cards', 'busy'], true),
        set(['cards', 'error'], false)
      ])(state);
    }
    case AT.FETCH_PAYMENT_METHODS_FAIL: {
      return flow([
        set(['cards', 'list'], []),
        set(['cards', 'busy'], false),
        set(['cards', 'error'], true)
      ])(state);
    }
    case AT.FETCH_PAYMENT_METHODS_SUCCESS: {
      const cardsList = payload || [];
      let defaultCard =
        payload.find((card) => get(card, 'defaultPaymentMethod', false) === true) || {};
      if (!isEmpty(defaultCard)) {
        defaultCard = new DefaultCardConstructor(
          get(defaultCard, 'metadata.last4'),
          get(defaultCard, 'metadata.cardType'),
          get(defaultCard, 'metadata.expirationDate')
        );
      }
      return flow([
        set(['cards', 'list'], cardsList),
        set(['cards', 'default'], defaultCard),
        set(['cards', 'busy'], false),
        set(['busy'], false),
        set(['error'], false),
        set(['cards', 'error'], false),
        set(['userCardsInitialized'], true)
      ])(state);
    }
    case AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES: {
      return flow([set(['paymentMethodTypes'], []), set(['busy'], true), set(['error'], false)])(
        state
      );
    }
    case AT.SET_IS_CHANGING_CARD: {
      return flow([set(['userCards', 'isChangingCard'], payload)])(state);
    }
    case AT.FETCH_PSP_KEY_SUCCESS: {
      const { response } = payload;
      return flow([set(['PSPKeys', 'stripe'], response.stripeKey)])(state);
    }
    case AT.SELECTED_PAYMENT_METHOD_CHANGED: {
      const { method, pspName } = payload;
      return flow([
        set(['selectedPaymentMethod', 'method'], method),
        set(
          ['selectedPaymentMethod', 'pspName'],
          pspName ? pspName : get(state, ['paymentMethodTypes', method, '0', 'pspName'])
        )
      ])(state);
    }
    case AT.INIT_PAYMENT_DATA: {
      const { paymentAmount, shipmentNumber, initialPaymentMethodType } = payload;
      return flow([
        set(['currentTransaction', 'metadata', 'shipmentNumber'], shipmentNumber),
        set(['currentTransaction', 'initialPaymentMethodType'], initialPaymentMethodType),
        set(['currentTransaction', 'paymentAmount'], paymentAmount)
      ])(state);
    }
    case AT.FETCH_USER_CARDS_SUCCESS: {
      const { cardsList, defaultCard } = payload;
      return flow([
        set(['userCards', 'list'], cardsList),
        set(['userCards', 'default'], defaultCard),
        set(['freightosBankDetails', 'fetching'], true)
      ])(state);
    }
    case AT.FETCH_BANK_DETAILS: {
      return flow([
        set(['freightosBankDetails', 'details'], {}),
        set(['freightosBankDetails', 'fetching'], true)
      ])(state);
    }
    case AT.FETCH_BANK_DETAILS_SUCCESS: {
      const { bankDetails } = payload;
      return flow([
        set(['freightosBankDetails', 'details'], bankDetails),
        set(['freightosBankDetails', 'fetching'], false)
      ])(state);
    }
    case AT.FETCH_BANK_DETAILS_FAIL: {
      return flow([
        set(['freightosBankDetails', 'details'], {}),
        set(['freightosBankDetails', 'fetching'], false)
      ])(state);
    }
    case AT.SAVE_CARD_TOKEN:
    case AT.DO_PAYMENT: {
      return flow([set(['busy'], true), set(['cardErrorMessage'], null)])(state);
    }
    case AT.SAVE_CARD_TOKEN_FAIL: {
      const isIssueFromCard = get(payload, 'isIssueFromCard', false);
      return flow([
        set(['busy'], false),
        set(['cardErrorMessage'], isIssueFromCard ? 'Your card was declined' : null)
      ])(state);
    }
    case AT.SAVE_CARD_TOKEN_SUCCESS: {
      return flow([set(['busy'], false), set(['cardErrorMessage'], null)])(state);
    }
    case AT.DO_PAYMENT_FAIL: {
      let uiFacingError = undefined;
      if (payload && payload.error && payload && payload.error.startsWith('STDERR:')) {
        uiFacingError = payload && payload.error.replace('STDERR:', '');
      }
      return flow([set(['busy'], false), set(['cardErrorMessage'], uiFacingError)])(state);
    }
    case AT.DO_PAYMENT_SUCCESS: {
      return flow([set(['busy'], false)])(state);
    }
    case AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_SUCCESS: {
      let availableCredit = {};
      let { availableMethods, amount, currency, btIntegrationIsEnabled } = payload;
      let pspKeys = {};
      if (btIntegrationIsEnabled) {
        // map gateways to payment methods data
        availableMethods = availableMethods.reduce((c, gateway) => {
          gateway.paymentMethods.forEach((method) =>
            c.push({
              paymentMethodType: get(method, 'paymentMethodType'),
              pspName: get(gateway, 'gatewayName'),
              pspMetadata: { ...get(method, 'metadata', {}), ...get(gateway, 'metadata', {}) }
            })
          );
          return c;
        }, []);
      }
      const SUPPORTED_PAYMENT_METHODS_KEYS = Object.values(SUPPORTED_PAYMENT_METHODS_TYPES);
      const paymentMethodTypesResult = availableMethods
        .filter((m) => SUPPORTED_PAYMENT_METHODS_KEYS.includes(m['paymentMethodType']))
        .reduce((c, a) => {
          if (a['paymentMethodType'] === SUPPORTED_PAYMENT_METHODS_TYPES.CARD) {
            pspKeys[a.pspName] = get(a, ['pspMetadata', 'tokenizationKey']);
          }
          if (a['paymentMethodType'] === SUPPORTED_PAYMENT_METHODS_TYPES.CREDIT_LINE) {
            availableCredit = {
              amount: get(a, ['pspMetadata', 'availableCredit']),
              currency: get(a, ['pspMetadata', 'currency'])
            };
            if (
              Number(get(a, ['pspMetadata', 'availableCredit'], 0)) < amount ||
              get(a, ['pspMetadata', 'currency'], null) !== currency
            ) {
              a['UIMetadata'] = {
                disabled: true
              };
            }
          }
          c[a['paymentMethodType']] = c[a['paymentMethodType']] || [];
          if (
            a['paymentMethodType'] === SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER &&
            a['pspName'] === SUPPORTED_MONEY_TRANSFER_PSP_NAMES.VEEM
          ) {
            c[a['paymentMethodType']].unshift(a);
          } else {
            c[a['paymentMethodType']].push(a);
          }
          return c;
        }, {});
      const selectedPaymentMethod =
        state.currentTransaction.initialPaymentMethodType === 'Cash'
          ? SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER
          : Object.keys(paymentMethodTypesResult)
              .sort(
                (a, b) =>
                  SUPPORTED_PAYMENT_METHODS_KEYS.indexOf(a) -
                  SUPPORTED_PAYMENT_METHODS_KEYS.indexOf(b)
              )
              .find((m) => {
                return (
                  paymentMethodTypesResult[m].filter((n) => get(n, ['UIMetadata', 'disabled']))
                    .length !== paymentMethodTypesResult[m].length
                );
              });
      const selectedPspName = get(
        paymentMethodTypesResult,
        [selectedPaymentMethod, '0', 'pspName'],
        ''
      );
      // making sure previous method is still available
      let previousSelectedPSPName = get(state, ['selectedPaymentMethod', 'pspName'], null);
      previousSelectedPSPName = availableMethods
        .map((m) => m.pspName)
        .find((m) => m === previousSelectedPSPName);
      let previousSelectedPaymentMethod = get(state, ['selectedPaymentMethod', 'method'], null);
      previousSelectedPaymentMethod = availableMethods
        .map((m) => m.paymentMethodType)
        .find((m) => m === previousSelectedPaymentMethod);
      return flow([
        set(['PSPKeys'], pspKeys),
        set(['paymentMethodTypes'], paymentMethodTypesResult),
        set(['availableCredit'], availableCredit),
        set(
          ['selectedPaymentMethod', 'method'],
          previousSelectedPaymentMethod || selectedPaymentMethod
        ),
        set(['selectedPaymentMethod', 'pspName'], previousSelectedPSPName || selectedPspName),
        set(['busy'], false),
        set(['error'], false)
      ])(state);
    }
    default:
      return state;
  }
};

export default reducer;
