import { connect } from 'react-redux';
import * as selectors from 'financialSharedComponents/selectors';
import BankDetails from './BankDetails';
import { fetchBankDetails } from './actions';

const mapStateToProps = (state) => {
  return {
    bankDetails: selectors.bankDetailsSelector(state),
    fetching: selectors.bankDetailsFetchingSelector(state),
    buyerCurrency: selectors.currentTransactionCurrencySelector(state)
  };
};
export default connect(mapStateToProps, { fetchBankDetails })(BankDetails);
