import React from 'react';
import { connect } from 'react-redux';
import * as selectors from 'financialSharedComponents/selectors';
import StripeTokenizeForm from './StripeTokenizeForm';
import BrainTreeTokenizeForm from './BrainTreeTokenizeForm';
import { SUPPORTED_TOKENIZE_FORMS_PSP_NAMES } from '../constants';
import { Elements, StripeProvider } from 'react-stripe-elements';

const CreditCardTokenizeFormFactory = ({
  pspName,
  brainTreeApiKey,
  stripeApiKey,
  onSubmitToken,
  isSavingToken,
  payButtonTitle
}) => {
  return (
    <React.Fragment>
      {pspName === SUPPORTED_TOKENIZE_FORMS_PSP_NAMES.BRAIN_TREE && brainTreeApiKey && (
        <BrainTreeTokenizeForm
          payButtonTitle={payButtonTitle}
          isSavingToken={isSavingToken}
          onSubmitToken={onSubmitToken}
          tokenizeKey={brainTreeApiKey}
        />
      )}
      {pspName === SUPPORTED_TOKENIZE_FORMS_PSP_NAMES.STRIPE && stripeApiKey && (
        <StripeProvider apiKey={stripeApiKey}>
          <Elements>
            <StripeTokenizeForm
              payButtonTitle={payButtonTitle}
              isSavingToken={isSavingToken}
              onSubmitToken={onSubmitToken}
            />
          </Elements>
        </StripeProvider>
      )}
    </React.Fragment>
  );
};

CreditCardTokenizeFormFactory.propTypes = {};

const mapStateToProps = (state) => ({
  stripeApiKey: selectors.stripeApiKeySelector(state),
  isSavingToken: selectors.paymentMethodTypesBusySelector(state),
  brainTreeApiKey: selectors.brainTreeApiKeySelector(state),
  pspName: selectors.selectedPaymentMethodPSPNameSelector(state)
});

export default connect(mapStateToProps)(CreditCardTokenizeFormFactory);
