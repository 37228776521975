import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import * as monitoring from '@propera/monitoring';
import { store } from 'microfronts-redux';
import { model as userModel } from 'propera/user';
import { flagStateOrDefaultValueSelector } from 'feature-flagging';
import { doPostRequest, doGetRequest } from '@propera/HTTP';
import * as AT from 'financialSharedComponents/constants/actionTypes';
import * as Actions from './actions';
import { fetchLHtoken } from 'financialSharedComponents/api.saga';
import { getLhGatewayBaseUrl } from '../../constants/lhEndpoints';
import * as selectors from 'financialSharedComponents/selectors';
import * as ldFlags from 'financialSharedComponents/ldFlags';
import { STRIPE_FORM_SHARABLE_KEY } from 'financialSharedComponents/constants/endpoints';

export const businessKeySelector = (state) => userModel.getBusinessKey(state);
const currentState = (state) => state;

export function* fetchSupportedPaymentTypes({ payload }) {
  try {
    const { amount, currency } = payload;
    const state = yield select(currentState);
    const btIntegrationIsEnabled = flagStateOrDefaultValueSelector(
      state,
      ldFlags.BRAIN_TREE_INTEGRATION_FLAG,
      true
    );
    const businessKey = yield select(businessKeySelector);
    const token = yield call(fetchLHtoken);
    const SUPPORTED_GATEWAYS_API_PATH =
      '/billing-page/data/lh-billing/payments/list-supported-methods';
    const SUPPORTED_GATEWAYS_API_PATH_WITH_BT_INTEGRATION =
      '/billing-page/data/lh-billing/payment-methods/supported-gateways';
    const response = yield call(
      doPostRequest,
      btIntegrationIsEnabled
        ? SUPPORTED_GATEWAYS_API_PATH_WITH_BT_INTEGRATION
        : SUPPORTED_GATEWAYS_API_PATH,
      {
        paymentAmount: amount,
        currency,
        tenantMetadata: {
          businessKey: `${businessKey}`
        }
      },
      { Accept: 'application/json', Authorization: `Application ${token}` },
      undefined,
      undefined,
      getLhGatewayBaseUrl()
    );
    if (response) {
      yield put(
        Actions.fetchSupportedPaymentTypesSuccess({
          availableMethods: response,
          btIntegrationIsEnabled,
          ...payload
        })
      );
      if (!btIntegrationIsEnabled) {
        yield put(Actions.fetchPSPKey());
      }
    }
  } catch (err) {
    yield put(Actions.fetchSupportedPaymentTypesFail());
    yield call(monitoring.utils.exceptionReporter, err, store.getState);
  }
}

export function* doPayment() {
  try {
    const state = yield select(currentState);
    const btIntegrationIsEnabled = flagStateOrDefaultValueSelector(
      state,
      ldFlags.BRAIN_TREE_INTEGRATION_FLAG,
      true
    );
    const shipmentNumber = selectors.currentTransactionShipmentNumberSelector(state);
    if (!shipmentNumber) {
      throw new Error('missing_arguments__shipmentNumber');
    }
    const paymentMethodType = selectors.selectedPaymentMethodSelector(state);
    const paymentAmount = selectors.currentTransactionAmountSelector(state);
    const currency = selectors.currentTransactionCurrencySelector(state);
    const data = {
      paymentMethodType,
      paymentAmount,
      currency,
      paymentGateway: btIntegrationIsEnabled ? 'Braintree' : 'Stripe'
    };
    const token = yield call(fetchLHtoken);
    yield call(
      doPostRequest,
      `/billing-page/data/lh-billing/payments/${shipmentNumber}/pay`,
      { ...data },
      { Accept: 'application/json', Authorization: `Application ${token}` },
      undefined,
      undefined,
      getLhGatewayBaseUrl()
    );
    yield put(Actions.doPaymentSuccess({ paymentMethodType }));
  } catch (err) {
    const error = err && err.response;
    yield put(Actions.doPaymentFail(error));
    
    if (!JSON.stringify(error).toLowerCase().includes('declined') || !error) {
      yield call(monitoring.utils.exceptionReporter, err, store.getState);
    }
  }
}

export function* fetchPSPKey() {
  try {
    const token = yield call(fetchLHtoken);
    const response = yield call(
      doGetRequest,
      STRIPE_FORM_SHARABLE_KEY,
      { Authorization: `Application ${token}` },
      {},
      true,
      true,
      getLhGatewayBaseUrl()
    );
    if (response) {
      yield put(Actions.fetchPSPKeySuccess({ response }));
    }
  } catch (err) {
    yield call(monitoring.utils.exceptionReporter, err, store.getState);
  }
}

export function* fetchPaymentMethods() {
  try {
    const token = yield call(fetchLHtoken);
    const businessKey = yield select(businessKeySelector);
    const response = yield call(
      doGetRequest,
      `/billing-page/data/lh-billing/payment-methods/${businessKey}`,
      { Accept: 'application/json', Authorization: `Application ${token}` },
      {},
      true,
      true,
      getLhGatewayBaseUrl()
    );
    if (response) {
      yield put(Actions.fetchPaymentMethodsSuccess(response));
    }
  } catch (err) {
    yield put(Actions.fetchPaymentMethodsFail());
    yield call(monitoring.utils.exceptionReporter, err, store.getState);
  }
}

export default function* watcher() {
  yield all([
    takeLatest(AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES, fetchSupportedPaymentTypes),
    takeLatest(AT.DO_PAYMENT, doPayment),
    takeLatest(AT.FETCH_PAYMENT_METHODS, fetchPaymentMethods),
    takeLatest(AT.FETCH_PSP_KEY, fetchPSPKey)
  ]);
}
