import React from 'react';
import { Icon, Button, RadioButtonGroup } from '@freightos/design-system';
import get from 'lodash/get';
import Classnames from 'classnames';
import styles from './creditCards.module.scss';
import { t } from 'utils/translationProvider';
import { isCardExpiryDatePassed } from 'utils/financial.utils';

const CreditCardLabel = ({ card, setIsChangingCard }) => {
  return (
    <div className={Classnames(styles.creditCard, 'clearfix')}>
      <Icon type={'credit-card'} size={'large'} />
      <div className={styles.details}>
        <div className={styles.cardNumber}>
          <span>****</span>
          <span>****</span>
          <span>****</span>
          <span data-test-id="card-last4">{card.last4}</span>
        </div>
        <div
          data-test-id="card-expiry-date"
          className={Classnames(styles.exp, {
            [styles.datePassed]: isCardExpiryDatePassed(card)
          })}
        >{`Exp. ${card.expiry}`}</div>
      </div>
      <Button
        data-test-id="change-card-button"
        onClick={() => {
          setIsChangingCard(true);
        }}
        className={styles.changeCard}
        type={'text'}
        size={'medium'}
      >
        {t('Change card', 'Change card')}
      </Button>
    </div>
  );
};

const CreditCardList = ({ disabled, defaultCard, setIsChangingCard }) => {
  const options = [
    {
      value: get(defaultCard, 'last4', null),
      label: <CreditCardLabel setIsChangingCard={setIsChangingCard} card={defaultCard} />
    }
  ];
  return (
    <RadioButtonGroup
      className="financial-separated-radio-button-group financial-user-cards"
      defaultValue={defaultCard.last4}
      options={options}
      size="large"
    />
  );
};

export default CreditCardList;
