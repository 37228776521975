import { getRootDomain } from 'propera/utils/url';

/*
 * lh token should be deprecated and start using mp auth so this file should be deleted once that happens
 * */

export const TEST_DOMAIN = 'test-freightos.com';
const CHINA_URL_FOR_STAGING = 'lhchina.freightos.cn';
const STAGING_DOMAIN = 'staging-freightos.com';
const CHINA_URL_FOR_PRODUCTION = 'ship.freightos.cn';
const GW_LOCAL_PATH = 'http://lighthouse.freightos.local:8080/';
const PRODUCTION_DOMAIN = 'freightos.com';
const GW_SUB_DOMAIN = 'lighthouse';

export const FREIGHTOS_AUTHENTICATION_ENDPOINT =
  'servlet/apptoken?app=lh-transportplan&includeData=true';

export const getLhGatewayBaseUrl = () => {
  const { href, protocol } = window.location;
  let domain = getRootDomain(href);

  if (domain.includes('local')) {
    // local - go to local GW
    return GW_LOCAL_PATH;
  }
  if (domain.includes('appspot')) {
    // appspot - go to test domain
    domain = TEST_DOMAIN;
  }
  if (href.includes(CHINA_URL_FOR_STAGING)) {
    // lhchina.freightos.cn - go to staging domain, staging-freightos.com
    domain = STAGING_DOMAIN;
  }
  if (href.includes(CHINA_URL_FOR_PRODUCTION)) {
    // ship.freightos.cn - go to production domain, freightos.com
    domain = PRODUCTION_DOMAIN;
  }
  return `${protocol}//${GW_SUB_DOMAIN}.${domain}`;
};

export const getFreightosServerEndpoint = () => {
  const { search, href } = window.location;
  if (getRootDomain(href).includes('local')) {
    const params = search.slice(1) ? `&${search.slice(1)}` : '';
    return `${FREIGHTOS_AUTHENTICATION_ENDPOINT}${params}`;
  }
  return FREIGHTOS_AUTHENTICATION_ENDPOINT;
};

export const getFreightosServerBaseUrl = () => {
  const { protocol, host, href } = window.location;
  const domain = getRootDomain(href);

  if (domain.includes('local')) {
    // local - go to local GW
    return GW_LOCAL_PATH;
    // in order to use local freightos BE use this
    // return 'http://ship.freightos.local:8888/';
  }
  return `${protocol}//${host}`;
};

export const LH_GATEWAY_VERIFY_END_POINT = 'verify';
