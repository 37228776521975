import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Avatar, Collapse, Panel, Row, Col } from '@freightos/design-system';
import Loading from 'financialSharedComponents/components/common/Loading';
import { BANK_TRANSFER_SUPPORTED_CURRENCIES } from 'financialSharedComponents/constants/app.constants';
import './bankDetails.scss';

const WireAccountDetails = ({ accountDetails }) => (
  <div className={'wireDetailsContent'}>
    <Row>Recipient&apos;s name</Row>
    <Row className={'infoRow'}>{accountDetails.accountHolder}</Row>
    <Row>Recipient&apos;s address</Row>
    <Row className={'infoRow'}>{accountDetails.address}</Row>
    <Row>
      <Col span="12">Account number</Col>
      <Col span="12">Bank name</Col>
    </Row>
    <Row className={'infoRow'}>
      <Col span="12">{accountDetails.accountNumber}</Col>
      <Col span="12">{accountDetails.bankName}</Col>
    </Row>
    <Row>Bank address</Row>
    <Row className={'infoRow'}>{accountDetails.bankAddress}</Row>

    <Row>
      {accountDetails.abaRouteCode && <Col span="12">ABA routing</Col>}
      {accountDetails.bicCode && <Col span="12">Swift code</Col>}
      {accountDetails.iban && <Col span="12">IBAN</Col>}
    </Row>
    <Row className={'infoRow'}>
      {accountDetails.abaRouteCode && <Col span="12">{accountDetails.abaRouteCode}</Col>}
      {accountDetails.bicCode && <Col span="12">{accountDetails.bicCode}</Col>}
      {accountDetails.iban && <Col span="12">{accountDetails.iban}</Col>}
    </Row>
    {accountDetails.sortCode && (
      <React.Fragment>
        <Row>
          <Col span="12">Sort Code</Col>
        </Row>
        <Row className={'infoRow'}>
          <Col span="12">{accountDetails.sortCode}</Col>
        </Row>
      </React.Fragment>
    )}
  </div>
);

const BankDetails = ({ fetchBankDetails, buyerCurrency, bankDetails, fetching }) => {
  useEffect(() => {
    fetchBankDetails();
  }, []);
  const supportedCurrencies = BANK_TRANSFER_SUPPORTED_CURRENCIES || [];
  const buyerBankDetails = bankDetails[supportedCurrencies.find((key) => key === buyerCurrency)];
  return (
    <React.Fragment>
      {fetching && <Loading />}
      {!fetching && !isEmpty(buyerBankDetails) && (
        <div data-test-id="financial-bank-details" className={'financial-bank-details'}>
          <Collapse
            defaultActiveKey={[buyerCurrency]}
            accordion
            expandIconPosition="right"
            className={'wirePaymentDetailsCollapse'}
          >
            <React.Fragment>
              <Panel
                className={'mobilePanel'}
                key={buyerCurrency}
                showArrow={false}
                header={
                  <div data-test-id={`bank-transfer-panel-${buyerCurrency}`}>
                    <Avatar type="flag" country={buyerCurrency.substring(0, 2)} size="small" />
                    <span className={'wireDetailsHeader'}>
                      {buyerCurrency} bank account details
                    </span>
                  </div>
                }
              >
                <WireAccountDetails accountDetails={buyerBankDetails} />
              </Panel>
            </React.Fragment>
          </Collapse>
        </div>
      )}
    </React.Fragment>
  );
};

BankDetails.propTypes = {};

export default BankDetails;
