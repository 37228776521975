import React, { useEffect } from 'react';
import { t } from 'utils/translationProvider';
import get from 'lodash/get';
import Loading from 'financialSharedComponents/components/common/Loading';
import { Icon, Button } from '@freightos/design-system';
import CreditCardTokenizeFormFactory from './CreditCardTokenizeForm';
import CreditCardList from './CreditCardList';

const FreightosAutoPayBadge = () => (
  <div className="freightos-autopay-badge children-vertical-align-middle">
    <span className={'autopay-text-span'}>{t('FREIGHTOS AUTOPAY', 'FREIGHTOS AUTOPAY')}</span>{' '}
    <Icon type={'check-circled'} color={'#47A96E'} size={'small'} />
  </div>
);

const CreditCardsMgmt = ({
  isBTIntegrationEnabled,
  onSubmitToken,
  fetchPaymentMethods,
  fetchUserCards,
  isCardsListInitialized,
  defaultCard,
  setIsChangingCard,
  isChangingCard
}) => {
  useEffect(() => {
    if (isBTIntegrationEnabled) {
      fetchPaymentMethods();
    } else {
      fetchUserCards();
    }
  }, []);
  const isDefaultCardRegistered = get(defaultCard, 'registered', false);
  useEffect(() => {
    if (!isDefaultCardRegistered) {
      if (isBTIntegrationEnabled && isCardsListInitialized) {
        setIsChangingCard(true);
      } else if (!isBTIntegrationEnabled && defaultCard) {
        setIsChangingCard(true);
      }
    }
  }, [isDefaultCardRegistered, isCardsListInitialized, defaultCard]);
  return (
    <React.Fragment>
      {((isBTIntegrationEnabled && !isCardsListInitialized) ||
        (!isBTIntegrationEnabled && !defaultCard)) && <Loading />}
      {((isBTIntegrationEnabled && isCardsListInitialized) || defaultCard) && (
        <div data-test-id="credit-card-management-header-div">
          {!isChangingCard && isDefaultCardRegistered && (
            <React.Fragment>
              <div className="margin-bottom font-bolded children-vertical-align-middle">
                <span>
                  <b>{t('Use my saved credit card', 'Use my saved credit card')}</b>
                </span>
                <FreightosAutoPayBadge />
              </div>
              <CreditCardList defaultCard={defaultCard} />
            </React.Fragment>
          )}
          {(!isDefaultCardRegistered || isChangingCard) && (
            <React.Fragment>
              <div className="margin-bottom font-bolded children-vertical-align-middle">
                <span>
                  {!isDefaultCardRegistered && (
                    <b>
                      {t(
                        'actionRequired/payment_add_cc_details_header2_text',
                        'Add your credit card details'
                      )}
                    </b>
                  )}
                  {isDefaultCardRegistered && isChangingCard && (
                    <b>
                      Enter updated credit card details or{' '}
                      <Button
                        className={'use-my-saved-card-text'}
                        type={'text'}
                        onClick={() => {
                          setIsChangingCard(false);
                        }}
                      >
                        use saved card
                      </Button>
                    </b>
                  )}
                </span>
              </div>
              <CreditCardTokenizeFormFactory onSubmitToken={onSubmitToken} />
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

CreditCardsMgmt.propTypes = {};

export default CreditCardsMgmt;
